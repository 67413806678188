.sec-about , .ab-servs {
   .ab-image {
      @media (max-width : $mediaMd) {
         text-align: center;
         margin-bottom: 30px;
      }
      @media (max-width: $mediaXs){
         max-height: 250px;
         overflow: hidden;
         img {
            width: 100%;
         }
      }
   }
   .ab-text {
      margin-bottom: 30px;
      h4 {
         font-family: $fontOne;
         text-transform: uppercase;
         color : $grayOne;
         font-weight: $bolder;
         @include font-size(18);
         margin-bottom: 25px;
         position : relative;
         .icon-big {
            transform : translateY(10px);
         }
      }
      p {
         @include font-size(15);
         color : $grayTwo;
      }
      &.ab-right {
         h4 {
            text-align: right;
         }
         .icon-circle {
            margin-left: 10px;
            margin-right: 0;
         }
      }
      .ab-social {
         li {
            display: inline-block;
            margin: 15px 30px 15px 0;
            a {
               color: $grayOne;
               font-size: 22px;
               @include transition();
               &:hover{
                  color : $yellow;
               }
            }
         }
      }

      @media (max-width : $mediaSm){
         h4 {
            @include font-size(16);
         }
      }
   }
   .primary-list {
      li {
         width: 100%;
         display: block;
      }
   }
   .ab-side-img {
      text-align: center;
      img {
         margin-bottom: 15px;
         @media (max-width: $mediaSm){
            max-width: 280px;
            
         }
      }
   }
   .icon-circle {
      transform : translateY(3px) ;
   }
}
//About services
.ab-servs {
   background-color: #f7f7f7;
}
//Skills Bar
.skills-bars {
   p {
      font-size: 15px;
      font-weight: $bolder;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 20px;
   }
   .bar {
      height: 8px;
      display: block;
      width: 100%;
      background-color: #dcdcdc;
      margin-bottom: 25px;
   }
   .bar-load {
      display: block;
      height: 100%;
      background-color: $blueOne;
      width: 0;
      transition : all .5s ;
   }
}
//about fun facts
.ab-facts {
   background-image : url(../images/fun-bg.jpg);
   background-size: cover;
   background-repeat:  no-repeat;
   background-attachment : fixed;
    position: relative;
   color: #fff;
   text-align: center;
   &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#389aed , .8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1 ;
   }
   .container {
      position: relative;
      z-index: 5;
   }
   .sec-header {
      h3 , p {
         color : #fff;

      }
   }

   .fun-fact {
      text-align: center;
      margin: 30px 0 15px;
      strong {
         font-weight: normal;
      }
      .fun-ico {
         width: 130px;
         height: 130px;
         border : $grayOne 8px solid;
         display: block;
         margin: 0 auto;
         border-radius : 100%;
         text-align: center;
         font-size: 45px;
         color : $yellow;
         line-height: 115px;
      }
      p {
         font-family: $fontOne;
         @include font-size(48);
         span {
            display: block;
            @include font-size(15);
            text-transform: uppercase;
         }
      }
   }
}
//About team
.ab-team {
   @extend .ab-facts;
   .ab-mem {
      max-width: 270px;
      margin: 0 auto;
      text-align: left;
      .mem-img {
         position: relative;
         overflow: hidden;
         margin-bottom: 15px;
         img {
            width: 100%;
         }
         &:before , &:after {
            content: "";
            position : absolute ;
            top : 0;
            left : 0;
            width: 100%;
            height: 100%;
            background-color: rgba($yellow , .1);
            transform : translateY(-150%);
            transition : all .8s ;
            opacity: 1;
         }
         &:after {
            transform : translateY(150%);
            transition : all .8s .1s;
         }
         .mem-social {
            position : absolute ;
            top : 0;
            left : 0;
            width: 100%;
            height: 100%;
            background-color: rgba($yellow , .8);
            text-align: center;
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity : 0;
            @include transition(.4s);
            a {
               color : #fff;
               margin: 0 15px;
               line-height: 50%;
            }
         }
         &:hover {
            &:after {
               transform : translateY(-150%);
               opacity: 1;
            }
            &:before {
               transform : translateY(150%);
               opacity: 1;
            }
            .mem-social {
               opacity : 1;
            }
         }
      }
      p {
         font-family: $fontOne;
         text-transform: uppercase;
         @include font-size(17);
         font-weight: $bold;
         letter-spacing: 1px;
      }
      span {
         font-family: $fontTwo;
         @include font-size(15);
         color : $yellow;
         margin-bottom: 30px;
         display: inline-block;
      }
   }
}