// Fonts
$fontOne 	: "Raleway" ;
$fontTwo 	: "Lato" ;


// Colors

$yellow 		:  #f3ba35 ;

$whiteOne	:  #fefefe ;

$grayOne 	:  #363636 ;
$grayTwo 	:  #868686 ;
$grayTre    :  #515151 ;

$grayFor		: 	#f7f7f7 ;

$blueOne    :  #2694f1 ;

// Font weight 
$light 	: 300 ;
$normal 	: 400 ;
$semi 	: 600;
$bold 	: 700;
$bolder 	: 900;

// Media Queries
$mediaRe : 320px;
$mediaXs : 480px;
$mediaSm : 768px;
$mediaMd : 960px;
$mediaLa : 1200px;

