.home-slider {
   .flexslider {
      overflow: hidden;
      width: 100%;
      border: none;
      margin: 0;
      .flex-direction-nav a {
         height: 100px;
         background-color: rgba(#000,.8);
         margin: -50px 0 0;
         right: 0 !important;
         line-height: 100px;
         color : #fff;
         padding: 0 12px;
         width: 35px;
         &.flex-prev {
            left: 0 !important ;
         }
         &:before {
            color : #fff;
            font-size: 20px;
         }
      }
   }
   .slides {
      li {
         position : relative;
         display: block;
         img {
            min-height: 285px;
         }
      }
   }
   .flex-active-slide {
      .slider-content {
         opacity: 1;
      }

      .slider-text {
         .taxonomy {
            opacity: 1;
            transform : translateX(0);
         }
         h2  , .car-type , p , a{
            transform : translateY(0);
            opacity: 1 ;
         }
      }
   }

   .slider-content {
      position : absolute ;
      top: 0;
      left : 0; 
      width: 100%;
      height: 100%;
      background-color: rgba(#000 , .5);
      z-index: 1;
      content: "";
      opacity: 0;
      @include transition();
   }
   .slider-text {
      position: absolute;
      width: 100%;
      top: 50%;
      transform : translateY(-50%);
      z-index: 10;
      color: #fff;
      padding: 0 200px;
      
      .taxonomy {
         font-family: $fontTwo;
         @include font-size(13);
         background-color: $blueOne;
         display: inline-block;
         border-left : 2px solid $yellow;
         padding: 7px 10px;
         margin-bottom: 30px;
         opacity: 0;
         transform : translateX(-50%);
         transition : all .4s .3s;
      }
      h2 {
         @include font-size(54);
         transform : translateY(30px);
         transition : all .3s .4s;
         opacity: 0;
      }
      .car-type {
         @include font-size(18);
         text-transform: uppercase;
         font-weight: $bolder;
         transform : translateY(30px);
         transition : all .3s .5s;
         display: inline-block;
         opacity: 0;
         &:after {
            content : "";
            width: 30px;
            height: 2px;
            background-color: $yellow;
            display: block;
            margin: 5px 0 20px;
         }
      }
      p {
         @include font-size(15);
         font-family: $fontTwo;
         max-width : 650px;
         margin-bottom: 30px;
         transform : translateY(30px);
         transition : all .3s .6s;
         opacity: 0;
      }
      a {
         transform : translateY(30px);
         transition : transform .3s .7s;
         opacity: 0;
         margin-bottom: 0;
         &:hover {
            background-color: $yellow;
         }
      }
      @media (max-width : $mediaMd){
         padding: 0 40px;
         h2 {
            @include font-size(30);
         }
         .taxonomy , p   {
            margin-bottom: 10px;
            @include font-size(14);
         }
         .car-type { 
            @include font-size(14);
            &:after {
               margin: 5px 0;
            }
         }
      }
   }
}