.sec-featured {
   .feat-bloc {
      background-color: $grayFor;
   }
}

.feat-bloc {
   font-family: $fontOne;
   margin: 0 auto 30px;
   background-color: #fff;
   .feat-img {
      overflow: hidden;
      display: block;
      img {
         transition : all .6s;
      }
      &:hover {
         &:after {
            background-color: rgba(#000,0);
         }
         img {
            transform : scale(1.1,1.1);
         }
      }
   }
   a {
      color: inherit;
      @include transition();
      &:hover {
         color: $yellow;
      }
   }
   .feat-content {
      position: relative;
      padding: 30px 20px;
      h3 {
         @include font-size(18);
         text-transform: uppercase;
         font-weight: $bolder;
         color: $grayOne;
 
      }
      p {
         font-family: $fontTwo;
         color: $grayTwo;
         @include font-size(15);
      }
   }
   ul {
      font-family: $fontTwo;
      overflow: hidden;
      li {
         float: left;
         width: (100 / 3) * 1% ;
         background-color: $blueOne;
         text-align: center;
         text-transform: capitalize;
         @include font-size(13);
         color : #fff;
         padding: 8px 0;
         border : 1px solid $blueOne;
         &:nth-of-type(2){
            border-left : 1px solid #000;
            border-right : 1px solid #000;
         }
         i {
            font-size: 16px;
            margin: 0 5px 0 0 ;
         }
      }
   }
   .badge {
      text-transform: uppercase;
      @include font-size(13);
      font-weight: $bolder;
      display: block;
      padding: 10px  ;
      text-align: center;
      width: 150px;
      position: absolute;
      left: 50%;
      margin-left: -70px;
      background-color: $yellow;
      color: #fff;
      top: 0;
      transform : translate(0,-100%);
      &.bg-blue {
         background-color: $blueOne;
      }
   }  
   .prix {
      @include font-size(15);
      color : $blueOne;
      font-weight: $bold;
      display: inline-block;
      margin-bottom: 15px;
   }
   @media (max-width : $mediaSm) {
      margin-bottom: 30px;
      max-width: 250px;
   }
   @media (max-width : $mediaXs){
      max-width: 100% ;
      img {
         width: 100%;
      }
   }
}


.page-listing {
   margin-bottom: 80px;
}
// Filtre Navigation

.filtre-nav {
   background-color: $blueOne;
   padding: 20px 30px 0;
   position: relative;
   z-index: 3 ;
   transform : translateY(-50%);
   max-width: 970px;
   margin: 0 auto 15px;
   .btn-yellow {
      border: none;
      &:hover {
         background-color: #fff;
      }
   }
   @media (max-width : $mediaSm){
      transform : translateY(0);
      margin: 15px auto;
   }
   p {
      text-transform: uppercase;
      color : $yellow;
      @include font-size(18);
      font-weight: $bolder;
      margin-bottom: 20px;
      padding-left: 10px;
   }

   ul {
      width: 100%;
      overflow: hidden;
      li {
         display: inline-block;
         width: (100 / 5) * 1% ;
         float: left;
         padding: 0 10px;

          select {
            color: #fff;
            font-family: $fontTwo;
            @include font-size(13);
            padding: 8px 15px;
            text-transform: capitalize;
            background-color: $blueOne;
         }

         input {
            border : none;
            padding: 8px 15px;
            font-size: 13px;
            font-weight: 800;
            font-family: $fontOne;
         }


         @media (max-width : $mediaSm){
            width: 100%;
            float: none;

         }
      }
   }
}

//
.feat2-bloc {
   text-align: center;
   margin-bottom: 30px;
   .icon-circle {
      margin: 0 auto 20px;
      display: block;
      i {
         font-size: 28px;
      }
   }
   h3 {
      font-weight: $bolder;
      @include font-size(19);
      color : $grayOne;
      text-transform: uppercase;
      margin-bottom: 10px;
   }
   p {
      font-size: 15px;
      font-family: $fontTwo;
      color : $grayTwo;
   }

}