.sec-partenaires {
   background-color: #f3f3f3;
}

.partenaires {
   width: 100%;   
   overflow: hidden;

   li {
      padding: 30px 100px;
      border-left : 1px solid #e4e4e4 ;
      width: 25%;
      float: left;
      text-align: center;
      margin-bottom: 15px;

      &:last-of-type {
         border-right : 1px solid #e4e4e4 ;
      }

      img {
         transition : all .5s;
      }
      &:hover img {
         transform : scale(1.5,1.5);
      }

      @media (max-width : $mediaMd){
         // width: 50%;
         padding: 20px 20px;
      }
      
      @media (max-width : $mediaSm){
         width: 100%;
         padding: 10px;
         float: none;
         border-right : 1px solid #e4e4e4 ;
      }

   }

   a {
      display: block;
   }
}