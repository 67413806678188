.sec-blog {

}
.bl-blog {
   position: relative;
   overflow: hidden;
   margin-bottom: 30px;

   @media (max-width: $mediaSm){
      margin: 0 auto 15px;
      max-width: 370px;
   }

   img {
      width: 100%;
   }

   &:hover {
      .bl-content {
         bottom: 0;
      }
   }
   &:after {
      position: absolute;
      left: 0;
      top : 0;
      background-color: rgba(#000,.2);
      width: 100%;
      height: 100%;
      content: "" ;
      z-index: 1;
   }
   .bl-content {
      position: absolute;
      z-index: 2;
      left: 0;
      bottom : -30px;
      color : #fff;
      width: 100%;
      padding: 0 35px;
      @include transition();
      .date {
         font-family: $fontTwo;
         @include font-size(12);
      }

      h3 {
         font-family: $fontOne;
         @include font-size(20);
         font-weight: $bolder;
         text-transform: uppercase;
      }

      .cats {
         margin-bottom: 55px;
         a {
            text-decoration: none;
            color: $yellow;
            font-family: $fontTwo;
            font-style: italic;
            @include font-size(12);
         }
      }
      .btn-yellow {
         margin-bottom: 0;
      }
   }
}

// Blog Page

.blog-page {
   padding: 100px 15px 70px;
}

// Blog posts

.blog-posts {
   margin-bottom: 70px;
}
// Side Bar

.side-bar {
   font-family: $fontTwo;
   color : $grayTwo;
   font-size: 15px;

   
   .side-wdg {
      margin-bottom: 45px;
      > h4 {
         color: $grayOne;
         text-transform: uppercase;
         font-weight: $bolder;
         font-size: 15px;
         margin-bottom: 10px;
      }

      a {
         color: $grayTwo;
         @include transition();
         font-family: $fontTwo;
         &:hover {
            color: $yellow;
         }
      }

      form {
         position: relative;
         input {
            margin-bottom: 0;
         }
         button {
            position: absolute;
            right: 0;
            top: 0;
            color: $yellow;
            background-color: transparent;
            outline: none;
            border: none;
            font-size: 20px;
            padding: 10px 15px;
         }
      }
   }

   .side-links {
      
      li {
         border-bottom : #e3e3e3 solid 1px ;
         padding: 10px 0;
      }
   }

   .side-tags {
      
      li {
         display: inline-block;
         padding: 5px 10px;
         border : 1px solid #e3e3e3;
         margin-bottom: 10px;
         margin-right: 5px;
      }
   }

   .side-posts {
      padding-top: 15px;

      li {
         position: relative;
         padding-left: 85px;
         margin-bottom: 20px;
         min-height: 75px;
      }
      img {
         max-width: 75px;
         max-height: 75px;
         position: absolute;
         left : 0;
         top : 0;
      }
      h3 {
         @include font-size(15);
         font-family: $fontTwo;
         color: $grayOne;
         font-weight: $bold;
         
         span {
            @include font-size(12);
            color: $grayTwo;
            display: block;
         }
      }
   }
}

// Post classic styles


.bl-post {
   margin-bottom: 40px;
   .post-img {
      margin-bottom: 20px;
      max-height: 400px;
      overflow: hidden;
   }

   .meta {
      @include font-size(12);
      font-family: $fontTwo;
      color: $grayOne;
      margin-bottom: 10px;
      span {
         margin-right: 30px;

         a {
            color: $yellow;
         }
      }
   }

   h3 {
      @include font-size(20);
      color: $grayOne;
      font-weight: $bolder;
      text-transform: uppercase;
      margin-bottom: 20px;
      a {
         color: inherit;
         &:hover {
            color: $yellow;
            @include transition();
         }
      }
   }

   p {
     @include font-size(15);
     color : $grayTwo; 
     font-family: $fontTwo;
     margin-bottom: 30px;
   }

   .btn-blue {
      padding: 10px 44px;
      padding-bottom: 10px;

   }
}
//blog comp
.post-img {
   margin-bottom: 25px;
   overflow: hidden;
   img {
      width: 100%;
      height: auto;
   }
}
.post-media {
   margin-bottom: 25px;
   width: 100%;
   overflow: hidden;
   video  , iframe{ 
      width: 100% !important;
   }
}
.media-video {
   position: relative;
   padding-bottom: 56.25%; /* 16:9 */
   padding-top: 25px;
   height: 0;
}
.media-video iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.post-info {
   font-size: 13px;
   text-transform: uppercase;
   margin-bottom: 10px;
   padding: 0 20px ;
   a {
      margin-right: 20px;
      color : $blueOne;
   }
}
.post-title {
   font-size: 16px;
   text-transform: uppercase;
   font-weight: $bold;
   margin-bottom: 15px;
   padding: 0 20px ;
   @include transition() ;
   a {
      color: $yellow;
   }
   &:hover {
      color: $blueOne;
   }
}
.post-excerpt {
   font-size: 15px;
   color: $grayTwo ;
   line-height: 26px;
   margin-bottom: 30px;
   padding: 0 20px ;
}
//Single post
.page-single {
   padding-top: 110px;
   padding-bottom: 70px;
   background: #fff;
}
%head {
   font-size: 16px;
   text-transform: uppercase;
   font-weight: $bold;
   margin-bottom: 15px;
   position: relative;
   color : $grayOne;
}
.single-article {
   margin-bottom: 30px;
   .blog-post {
      background: transparent;
      &:hover {
         img {
            transform : none;
         }
         .post-title {
            color: $blueOne;
         }
      }
   }
   .post-title {
      font-size: 22px;
      padding: 0;
    
   }
   .post-info {
      padding: 0;
   }
   p {
      font-size: 15px;
      color: $grayTwo ;
      line-height: 26px;
      margin-bottom: 30px;
      font-family: $fontTwo;
   }
   h1 {
      @extend %head ;
      font-size: 20px;
   }
   h2 {
      @extend %head ;
      font-size: 18px;
   }
   h3 {
      @extend %head ;
      font-size: 18px;
   }
   h4 ,h5 ,h6 {
      @extend %head ;
   }
   blockquote {
   padding: 20px;
   background: #fff;
   border-left : $blueOne 3px solid;
   margin-bottom: 30px;
   cite {
      text-transform:  capitalize ;
      font-size: 16px;
      font-family: $fontTwo;
      margin-bottom: 20px;
      margin-left: 35px;
      &:before {
         content : " - " ;
      }
   }
}
}
.single-title {
   @extend %head ;
   width: 100%;
   border-bottom: 1px solid #e3e3e3 ;
   padding-bottom: 20px;
   position: relative;
   margin-bottom: 40px;
   color: $yellow ;
   font-size: 15px;
}
.share-icons {
   min-width: 350px ;
   text-align: right;
   position: absolute;
   top : -5px ;
   right: 0 ;
   a {
      color: $yellow;
      margin-left: 15px;
   }
}
%roundImg {
   width: 100px;
   height: 100px;
   border-radius : 100% ;
   position : absolute ;
   top:  20px ;
   left  : 0 ;
}
.author-info {
   position: relative;
   padding: 20px 0 0 120px;
   color: $blueOne ;
   margin-bottom: 30px;
   img {
      @extend %roundImg ;
   }
   .user-name:after {
      content: " ";
      display: block;
      position: absolute;
      left: 0 ;
      bottom: 0 ;
      width: 30px;
      background: $blueOne;
      height: 3px;
   }
}

.mem-biou {
   color: $grayTwo;
}
.user-name {
   @extend %head ;
   margin-bottom: 10px;
   padding-bottom: 15px;
   color : $blueOne;
   span {
      font-size: 13px;
      font-family: $fontOne;
      font-weight: normal;
      &:before {
         content: " - " ;
      }
   }
}

.comment {
   position: relative;  
   padding :20px 20px 20px 80px;
   margin-bottom: 10px;
   p {
      color:  $grayOne  ;
      font-size: 15px;
      min-height: 26px;
      margin-bottom: 20px;

   }
   .user-name {
      padding: 0;
      font-size: 15px;
   }
   img {
      @extend %roundImg ;
      width: 60px;
      height: 60px;
   }

   &.comment-replay {
      @media (min-width : $mediaSm){
         margin-left: 60px;
      }
   }
}

.comment-links {
   margin-bottom: 25px;
   a {
      text-decoration: none;
      text-transform: uppercase;
      color: $blueOne ;
      font-size: 13px;
      padding-right: 10px;
      @include transition() ;
      &:hover {
         color: $blueOne ;
      }
   }
}

.comments-form {
   margin-bottom: 40px;
   .btn-blue {
      font-family: $fontOne;
   }
   textarea {
      height: 160px;
   }
}