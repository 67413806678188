.home-services {
   background-image: url(../images/services-bg.jpg);
   background-size: cover ;
   position: relative;

   &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#000 , .4);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1 ;
   }

   .container {
      position: relative;
      z-index: 2;
   }

   .sec-header {
      h3 {
         color : #fff;
      }

      > p {
         color: #fff;
      }
   }

}
.page-services {
   @extend .home-services;
   background : #fff;
   &:before {
      display: none;
   }
   .sec-header {
      h3 {
         color : $grayOne;
      }

      > p {
         color: $grayTwo;
      }
   }
   .serv-bloc {
      background-color: #f7f7f7;
   }
}

// Services Bloc
.serv-bloc {
   background-color: #fff;
   text-align: center;
   margin: 0 auto;
   position: relative;
   z-index: 3;

   .serv-img {
      border-bottom : 2px solid $yellow ;
      position: relative;
      margin-bottom: 55px;
      .icon-circle {
         background-color: $yellow;
         color: #fff;
         position: absolute;
         bottom: 0;
         left: 50%;
         transform : translate(-50%,50%);

      }
   }

   .serv-content {
      padding: 0 30px 10px;
      p {
         font-family: $fontTwo;
         color : $grayTwo;
         @include font-size(15);
         margin-bottom: 25px;
      }
      
      h3 {
         text-transform: uppercase;
         font-weight: $bolder;
         @include font-size(18);
         color: $yellow;
         margin-bottom: 25px;
      }
   }

   @media (max-width : $mediaSm) {
      margin-bottom: 30px;
      max-width: 250px;
   }

   @media (max-width : $mediaXs){
      max-width: 100% ;
      img {
         width: 100%;
      }
   }
}
//Bloc 2
.serv-bloc-2 {
   background-color: $grayFor;
   overflow: hidden;
   margin-bottom: 30px;
   a {
      color: inherit;
      @include transition();
      &:hover {
         color: $yellow;
      }
   }
   .serv-img {
      overflow: hidden;
      width: 50%;
      float: left;
      position : relative;
      &:after {
         width: 100%;
         height: 100%;
         content : "";
         position : absolute;
         display: block;
         top : 0;
         left : 0;
         background-color:rgba(#000,.3);
      }
      img {
         width: 100%;
         transition : all .5s;
      }
      &:hover {
         &:after {
            background-color: rgba(#000,0);
         }
         img {
            transform : scale(1.2,1.2);
         }
      }
   }
   .serv-content {
      padding: 30px;
      width: 50%;
      float: left;
      p {
         font-family: $fontTwo;
         color : $grayTwo;
         @include font-size(15);
      }
      h3 {
         text-transform: uppercase;
         font-weight: $bolder;
         @include font-size(18);
         color: $grayOne;
         margin-bottom: 10px;
      }
   }
   @media (max-width : $mediaSm){
      max-width: 270px;
      margin: 15px auto;
      .serv-img , .serv-content{
         width: 100%;
         float: none;
      }
   }
}
//Get Quote

.sec-quote {
   background-image : url(../images/quote-bg.jpg);
   background-size: cover;
   background-repeat:  no-repeat;
   background-attachment : fixed;
    position: relative;
   color: #fff;
   text-align: center;
   &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#389aed , .8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1 ;
   }
   .sec-header {
      h3 , p{
         color : #fff;
      }
   }
   .container {
      position: relative;
      z-index: 5;
   }
   p {
      max-width: 850px;
      margin: 0 auto;
      font-family: $fontTwo;
      @include font-size(15);
      padding: 0 15px;
      a {
         display: block;
         margin: 70px auto 10px;
         max-width : 230px;
         padding: 10px 25px;
      }
   }

}
.sec-serv-cars {
   background-image : url(../images/recent-bg.jpg);
   background-size: cover;
   background-repeat:  no-repeat;
   background-attachment : fixed;
   position: relative;
   color: #fff;
   text-align: center;
   &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#000 , .95);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1 ;
   }
   .sec-header {
      h3 , p{
         color : #fff;
      }
   }
   .container {
      position: relative;
      z-index: 5;
   }
}
//Services list
.sec-serv-list {
   .feat-list {
      background-color: #fff;
      margin: 0;
      padding: 0;

      li {
         width: (100 /3) * 1%;
         @media (max-width : $mediaMd){
            width: 100%;
            padding: 0;
         }
      }
   }
}
.sec-serv2-list{
   .btn-blue {
      margin: 70px auto 0;
      display: block;
      max-width: 200px;
      padding: 15px;
   }
}
/* Single Car */
.single-car {

   .car-info {
      h3 {
         @include font-size(18);
         color : $grayOne;
         font-weight: $bolder;
         text-transform: uppercase;
      }
      .car-price {
         color: $blueOne;
         @include font-size(15);
         font-weight: $bold;
         margin-bottom: 15px;
         display: inline-block;
      }
      > p {
         font-family: $fontTwo;
         @include font-size(15);
         color : $grayTwo;
         margin-bottom: 20px;
      }
      ul {
         font-family: $fontTwo;
         @include font-size(15);
         color : $grayTwo;
         margin-bottom: 30px;
         li {
            margin-bottom: 15px;
            border-bottom: 1px solid $grayFor;
            padding-bottom: 15px;
            strong {
               color : $grayOne;
               min-width: 100px;
               position : relative;
               display: inline-block;
               margin-right: 15px;
               &:after {
                  content : " : ";
                  position : absolute;
                  right : 0;
                  top : 0;
               }
            }
         }
      }
      .buyer-info {
         font-family: $fontTwo;
         margin-top: 15px;
         font-family: $fontTwo;
         @include font-size(15);
         color : $grayTwo;
         i {
            color : $blueOne;
            font-size: 25px;
            margin-right: 5px;
            display: inline-block;
            transform: translateY(4px);
         }
      }
   }
}
.cars-slider {
   overflow: hidden;
}
.cars-carousel {
   .flex-prev , .flex-next {
      top: 60%;
   }
}