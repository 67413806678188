// Top nav
.nav-top {
	background-color: $yellow;
	color: #fff;
	@include font-size(14);
	font-family: $fontTwo;
	font-weight: $bold;
	padding: 10px 0;
	span {
		margin-right: 40px;
		i {
			margin-right: 15px;
		}
	}
	.social-media {
		text-align: right;
		@include font-size(15);
		a {
			color: #fff;
			margin-left: 15px;
		}
	}
	@media (max-width : $mediaSm){
		text-align: center;
		@include font-size(13);
		span {
			margin-right: 10px;
			i {
				margin-right: 5px;
			}
		}
		.social-media {
			display: none;
		}
	}
}
// .End Top Nav Styles
// Navbar 
.site-header {
	background-color: $grayFor;
	font-family: $fontOne;
	position: relative;
	.site-logo {
		text-transform: uppercase;
		font-weight: $bolder;
		@include font-size(22);
		color: $yellow;
		margin-top: 25px;
		display: inline-block;
	}
	.site-nav {
		a {
			color: $grayOne;
			@include font-size(13);
			font-weight: $bold;
			text-transform: uppercase;
			@include transition();
			position: relative;
			i {
				@include font-size(15);
				position: absolute;
				top: -3px;
				right: -20px;
				@media (max-width : $mediaMd){
					top: 8px;
					right: -15px;
				}
			}
			&:hover {
				color: $yellow;
			}
		}
	 	// Menu
		> ul {
			text-align: right;
			&:after {
				display: table;
				content: " ";
				height: 1px;
				clear: both;
			}
			> li {
				display: inline-block;
				position: relative;
				padding: 30px 20px;
				text-align: center;
				cursor: pointer;
				&:last-of-type {
					padding: 30px 0 30px 20px ;
				}
				&.has-menu {
					a {
						margin-left: -20px;
					}
					@media (max-width: $mediaSm){
						a {
							margin-left: 0;
						}
					}
				}
				@media (max-width : $mediaMd){
					padding: 10px 10px;
					a {
						padding: 10px;
					}
					&:last-of-type {
						padding: 10px 0 10px 10px ;
						a {
							padding: 10px 0;
						}
					}
				}
				@media (min-width: $mediaSm){
					&:hover {
						ul {
							opacity: 1;
							transform : translate(0,0);
							z-index: 10;
						}
					}
				}
				// First Niveau
				ul {
					position: absolute;
					top: 100%;
					left: 0px;
					background-color: $grayFor;
					width: 175px;
					text-align: left;
					z-index: -1;
					transform : translate(0,200%);
					opacity: 0 ;
					@include transition();
					li {
						@include transition();
						padding: 10px 30px;

						&:hover {
							background-color: $yellow;	
							a {
								color: #fff;
							}	
						}
					}
				}
			}
		}
		// .End Sub Menu
		// Mobile Menu
		@media (max-width: $mediaSm){
			z-index: 100 ;
			> ul {
				display: none;
				width: 100%;
				background-color:$grayFor;
				left: 0;
				right: 0;
				> li {
					display: block;
					text-align: left;
					padding: 0;
					a {
						display: block;
						width: 100%;
						margin-left: 0;
						i {
							right: 5px;
						}
					}
					> ul {
						display: none;
						position: relative;
						opacity: 1;
						z-index: 1;
						top: 0;
						width: 100%;
						left: 0;
						transition : none ;
						transform : none;
						li {
							padding: 0 10px;
						}
					}
				}
			}
		}
		&.menu-open {
			> ul {
				display: block;
			}
		}
	}
	.menu-btn {
		display: none;
		z-index: 9999;
		position: absolute;
		right: 10px;
		top : 11px;
		text-indent: -9999px;
		appearance: none;
		box-shadow: none;
		border-radius: none;
		border: none;
		cursor: pointer;
		background-color: transparent;
		&:focus {
			outline: none;
		}
		/* Icon 1 */
		.btn-ico {
			width: 30px;
			height: 18px;
			position: relative;
			transform: rotate(0deg);
			transition: .5s ease-in-out;
			cursor: pointer;
			display: block;
			span {
			  display: block;
			  position: absolute;
			  height: 3px;
			  width: 100%;
			  background: $yellow;
			  border-radius: 9px;
			  opacity: 1;
			  left: 0;
			  transform: rotate(0deg);
			  transition: .25s ease-in-out;
				&:nth-child(1) {
				  top: 0px;
				}
				&:nth-child(2) {
				  top: 7px;
				}
				&:nth-child(3) {
				  top: 14px;
				}
			}
		}
		&.is-active {
			.btn-ico span{
				&:nth-child(1) {
			  		top: 7px;
			  		transform: rotate(135deg);
				}
				&:nth-child(2) {
				  opacity: 0;
				  left: -60px;
				}
				&:nth-child(3) {
				  top: 7px;
				  transform: rotate(-135deg);
				}
			}
		}
	}
	@media (max-width: $mediaMd){
		.site-logo {
			margin-top: 5px;
		}
	}
	@media (max-width : $mediaSm){
		padding-bottom: 5px;
		.site-nav {
		a {
				margin-left: 20px;
			}
		}
		.menu-btn {
			display: block;
		}
	}
}
// .End Navbar


// Page Head

.page-head {
	text-align: center;
	position: relative;
	background-image: url(../images/page-head.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 15px;
	@media (max-width: $mediaSm){
		padding: 30px 15px;
	}
	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(#000,.5);
		content: "";
		z-index: 1;
	}
	h3 {
		@include font-size(30);
		font-weight: $bolder;
		text-transform: uppercase;
		position: relative;
		z-index: 2;
		color: #fff;
	}

	.breadcrumb {
		position: relative;
		z-index: 2;

		a {
			color: #fff;
			@include font-size(15);
			text-transform: capitalize;
			@include transition();
			&:hover {
				color: $yellow;
			}
			&:after {
				content: "/" ;
				padding: 0 5px;
			}

			&:last-of-type {
				&:after {
					content: "";
				}
			}
		}
	}
}
// .End Page Head