* {
	box-sizing: border-box;
}

body {
	font-family: $fontOne;
	@include font-size();
}

a {
	text-decoration: none;
}

%heading {
   font-family: $fontOne;
}
h1 {
   @extend %heading;
	@include font-size(20); // 7
}
h2 {
   @extend %heading;
	@include font-size(30); // 12
}
h3 {
   @extend %heading;
	@include font-size(18); // 17
}

// Images

img {
   vertical-align: middle;
}
// Section

.section {
   padding: 90px 0;

   @media (max-width : $mediaMd){
      padding: 45px 0;
   }
}

// Section Header Title 

.sec-header {
   text-align: center;
   margin-bottom: 60px;
   h3 {
      font-family: $fontOne;
      @include font-size(30);
      color : $grayOne;
      font-weight: $bolder;
      text-transform: uppercase;
      margin-bottom: 5px;
   }

   p {
      font-family: $fontTwo;
      @include font-size(15);
   }
   
   .sub-title {
      color : $grayTwo;
      position: relative;
      max-width: 800px;
      margin: 0 auto;
      &:before {
         content: "";
         width: 12px;
         height: 12px;
         background-color: $yellow;
         display: block;
         transform : rotate(45deg);
         position: absolute;
         bottom: -5px;
         left: 50%;
         margin-left: -7px;
      }
      &:after {
         content: "";
         width: 50px;
         height: 1px;
         margin: 30px auto;
         background-color: $yellow;
         display: block;
      }
   }

   .desc {
      color : #000;
      margin: 0 auto 25px;
      max-width: 800px;
   }
}


// Icons

.icon-circle {
   color: $grayOne ;
   background-color: $grayFor;
   text-align: center;
   width: 70px;
   height: 70px;
   border-radius : 100% 100%;
   display: inline-block;
   text-align: center;
   font-size: 35px;
   line-height: 70px;
   margin-right: 10px;

   @media (max-width : $mediaSm){
      width: 45px;
      height: 45px;
      font-size: 25px;
      line-height: 45px;
   }
}
.icon-big {
   color: $grayOne;
   font-size: 55px;
   display: inline-block;
   margin-right: 15px;
}

// Buttons

%btn {
   @include font-size(13);
   font-weight: $bolder;
   text-align: center;
   color : $grayTwo;
   text-transform: uppercase;
   display: inline-block;
   padding: 10px 25px;
   margin-bottom: 30px;
}

.btn-gray {
   @extend %btn;
   background-color: $grayFor;  
   @include transition();
   &:hover {
      color : #fff;
      background-color: $blueOne;
   } 
}
.btn-yellow {
   @extend %btn;
   background-color: $yellow;  
   color : #fff;
   border: 1px solid $yellow;
   @include transition();
   &:hover {
      color : $yellow;
      background-color: transparent;
      border: 1px solid $yellow;
      border-bottom: none;
   } 
}
.btn-b-yellow {
   @extend %btn;
   background-color: transparent;  
   color : #fff;
   @include transition();
   border : 1px solid $yellow ;
   &:hover {
      color : $grayFor;
   } 
}
.btn-blue {
   @extend %btn;
   background-color: $blueOne;  
   color : #fff;
   @include transition();
   &:hover {
      color : #fff;
      background-color: $yellow;
   } 
}
.btn-white {
   @extend %btn;
   background-color: transparent;  
   border : 2px solid #fff;
   color : #fff;
   font-family: $fontOne;
   @include transition();

   &:hover {
      color : $yellow;
      background-color: #fff;
   } 
}

/* Page Content */
%siteUl {
   @include font-size(16);
   color: $grayTwo;
   margin-bottom: 25px;
   font-family: $fontTwo;
   li {
      position : relative;
      padding-left: 25px;
      width: 49%;
      display: inline-block;
      margin-bottom: 10px;
      &:before {
         font-family: "Ionicons";
         content : "\f3fd";
         color : $blueOne;
         font-size: 40px;
         font-weight: $bolder;
         position: absolute;
         left : 0; 
         top:0;


      }
   }
}
ul.primary-list {
   @extend %siteUl;
}
.page-content {
   .art-text {
      h3 {
         @include font-size(30);
         font-weight: $bolder;
         color: $grayOne;
         text-transform: uppercase;
         margin-bottom: 25px;
      }
      p {
         @include font-size(15);
         color: $grayTwo;
         margin-bottom: 25px;
         font-family: $fontTwo;
      }
      ul {
         @extend %siteUl;
      }
   }
   @media (max-width : $mediaSm){
      .art-img {
         max-width: 350px;
         margin: 0 auto 15px;
         overflow: hidden;
      }
      .art-text {
         h3 {
            @include font-size(25);
         }
         ul {
            li {
               width: 100%;
            }
         }
      }
   }
}