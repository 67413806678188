.sec-testimonial {
   background-color: $grayFor;
}

.testimonial-slider {
   text-align: center;
   .item {
      max-width: 700px;
      margin: 0 auto;
      padding: 0 15px;
      blockquote {
         @include font-size(22);
         font-style: italic;
         color : $grayTwo;
         font-family: $fontTwo;
         margin-bottom: 30px;
         min-height: 135px;
      }

      p {
         @include font-size(15);
         color : $grayOne;
         text-transform: uppercase;
         font-weight: $bolder;
         margin-bottom: 15px;
         span {
            // display: block;
            @include font-size(13);
            color : $grayTwo;
            font-family: $fontTwo;
            text-transform: none;
            &:before {
               content : ' - ';

            }
         }
      }

      @media (max-width : $mediaSm){
         blockquote {
            @include font-size(18);
            margin-bottom: 20px;
         }
         p {
            @include font-size(14);
         }
      }
   }
}

