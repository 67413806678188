.site-footer {
   background-color: #1f1f1f;
   color: #969696;
   font-family: $fontOne;
   padding: 60px 0;

   h3 {
      color: #fff;
      @include font-size(15);
      font-weight: $bold;
      text-transform: uppercase;
      margin-bottom: 25px;
   }
   p {
      @include font-size(12);
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 24px;
      a {
         color: $yellow;
      }

      span {
         @include font-size(12);
         display: block;
         margin: 15px 0;
      }
   }

   .foot-media {
      margin: 20px 0 15px;
      a {
         display: inline-block;
         margin-right: 18px;
         color: #969696;
         @include font-size(15)
      }
   }

   a {
      @include font-size(14);
      color : #969696;
      @include transition();
      &:hover {
         color: $yellow;
      }
   }

   .site-links {
      a {
         display: block;
         margin-bottom: 10px;
      }
   }

   .foot-insta {
      width: 100%;
      overflow: hidden;

      li {
         width: 25%;
         // float: left;
         display: inline-block;
         position: relative;
         margin-bottom: 5px;
         overflow: hidden;
         @media (max-width: $mediaSm){
            width: auto ;
         }
         &:hover span {
            opacity: 1;
            transform : scale(1,1);
         }

         span {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 65px;
            background-color: $yellow;
            text-align: center;
            font-size: 25px;
            color : #fff ;
            @include transition();
            opacity: 0;
            transform : scale(1.7,1.7);
         }
      }
   }
}

.copy-footer {
   text-align: center;
   @include font-size(12);
   background-color: #131313;
   padding: 20px 15px;
   color: #fff;

   i {
      color: #f95757;
      padding: 0 5px;
   }

}