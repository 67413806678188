// TABLE OF CONTENTS
/*
-- HEADER --
	TOP BAR OF INFOS / .nav-top
	SITE HEADER / .site-header
		LOGO / h1.site-logo
		MAIN NAVBAR / nav.site-nav
			SUBMENU / li.has-menu
-- END HEADER --

-- HOME PAGE --
	-- SECTIONS STRUCTURE --
	EVERY SECTION HAS THE SAME STRUCTURE WITH THE CHANGE OF THE CLASS NAME WITH EACH SECTION GIVEN A NAME THAT BETTER DESCRIBES IT
		ABOUT / .section sec-about
			EVERY SECTION HAS A TITLE & DESCRIPTION :
				.sec-header / CONTAINER OF THE TITLE & DESCRIPTION 
					h3 / TITLE
					p.sub-title / THE DESCRIPTION
		SERVICES / .section home-services
			SERVICES BLOC / .serv-bloc
				SERVICE IMAGE / .serv-img
				SERVICE CONTENT / .serv-content
					SERVICE TITLE / h3
					TEXT / p
		FEATURED CARS / .section sec-featured
			FEATURE BLOC / .feat-bloc
		FEATURES / .sec-features
			IMAGE / .featr-img
			LIST OF FEATURES / .feat-list
				TITLE / li h3
				TEXT / p
		GALLERY / .section sec-gallery
			GALLERY FILTERS / .gallery-tabs
		TESTIMONIALS / .section sec-testimonial
			ITEM / .item
				TESTIMONIAL TEXT / blockquote
		PARTNERS-CLIENTS / .section sec-partenaires
		BLOG / .section sec-blog
			BLOG POST / .bl-blog
-- END HOME PAGE --

-- PAGE HEAD --
	*EVERY PAGE HAS A SUB HEAD CONTAINS AN IMAGE AND TITLE
	CLASS NAME / .page-head
		TITLE / h3
		LOCATION ON WEBSITE / p.breadcrumb
-- END PAGE HEAD --

-- ABOUT US PAGE --
	WHY CHOOSE US SECTION / .section ab-servs
		.sec-header / CONTAINER OF THE TITLE & DESCRIPTION 
			h3 / TITLE
			p.sub-title / THE DESCRIPTION
		SKILL BARS / .skills-bars
		TEAM MEMBERS / .section ab-team
			MEMBER / .ab-mem
-- END ABOUT US PAGE --

-- ABOUT ME PAGE --
	FUN FACTS / .section ab-facts
		SINGLE FACT / .fun-fact
			ICON / .fun-ico
				i (USING IONICONS)
-- END ABOUT ME PAGE --

-- SERVICES PAGE -- 
	GET A QUOTE / .section sec-quote
	LIST OF SERVICES / .section sec-serv-list
		ALL SERVICES CONTAINER / ul.feat-list
			SERVICE TITLE / li h3
			SERVICE DESCRIPTION / p
-- END SERVICES PAGE --

-- SINGLE SERVICE PAGE --
	.section page-content
		SERVICE IMAGE / .art-img
		SERVICE DESCRIPTION / .art-text
-- END SINGLE SERVICE PAGE --

-- LISTING PAGE --
	PAGE CONTAINER / .page-listing
		FILTER BAR / .filtre-nav
-- END LISTING PAGE --

-- SINGLE CAR PAGE --
	PAGE CONTAINER / .section single-car
		SLIDER / #car-slider
			SLIDES-IMAGEs / ul.slides
				IMAGES / li img
		CAR INFORMATIONS / .car-info
-- END SINGLE CAR PAGE --

-- BLOG PAGE --
	.blog-page
		BLOG POST / .bl-post
		SIDE BAR / .side-bar
			WIDGETS / .side-wdg
-- END BLOG PAGE --

-- SINGLE ARTICLE PAGE --
	.blog-post
		SINGLE ARTICLE / article.single-article
		TITLES-LIKE SHARE,COMMENTS- / h3.single-title
		LEAVE A COMMENT / .comments-form
-- END SINGLE ARTICLE PAGE --

-- GALLERY PAGE --
	GALLERY FILTERS / .gallery-tabs
	IMAGES / #gallery .gallery-grid
		ITEM / .gallery-item
			a img
-- END GALLERY PAGE --

-- CONTACT US PAGE --
	CONTAINER / .contact-page
		ICON / .icon-circle
			USING IONICONS / i
		TITLE / h3
		TEXT / p
		CONTACT FORM / .contact-form
			INPUTS / p input
					 textarea
			BUTTON / .btn-blue
		GOOGLE MAP / #site-map .contact-map
-- END CONTACT US PAGE --

-- FOOTER --
	footer.site-footer
		SOCIAL MEDIA / .foot-media
		LIST OF LINKS / .site-links
		INSTAGRAM / ul.foot-insta
	COPYRIGHTS
		p.copy-footer
-- END FOOTER --




*/

// Css base
@import 'core/vars';

// CSS mixins
@import 'core/mixins';
@import 'core/reset';
@import 'core/fonts';
@import 'core/grid';


// Vendors 

 

// Site Styles

@import 'site/site';

@import 'site/site-header';
// Home Styles
@import 'site/home';
// About Styles
@import 'site/about'; 
// Services Styles 
@import 'site/services';
// Featured Styles
@import 'site/featured';
// Features
@import 'site/features';
// Gallery
@import 'site/gallery';
// Testimonials
@import 'site/testimonials';
// Partenaires
@import 'site/partenaires';
// Blog
@import 'site/blog';
// Footer
@import 'site/footer';
// Contact
@import 'site/contact';

//Vendors
@import 'vendors/owl-carousel';
@import 'vendors/flexslider';
@import 'vendors/magnific-popup';