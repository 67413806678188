.gallery-tabs {
  font-family: $fontTwo;
  @include font-size(14);
  color : $grayOne;
  margin-bottom: 55px;
  text-align: center;

  span {
    padding-bottom: 15px;
    margin: 5px 15px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    &:after {
      width: 25px;
      height: 2px;
      background-color: $yellow;
      position: absolute;
      left: 0;
      bottom: 0;
      content : "";
      @include transition();
    }

    &:hover  , &.tab-active{
      color: $yellow;
      font-weight: $normal;
      &:after {
        width: 100%;
      }
    }
  }
}

.gallery-grid {
   margin-left: -15px;
   margin-right: -15px;

   .col1 {
      width: 25%;
   }
   .col2 {
      width: 50%;
   }

   .gallery-item {
      overflow: hidden;
      margin-bottom: 30px;
      padding-right: 15px;
      padding-left: 15px;

      a {
        position: relative;
        overflow: hidden;
        display: block;
        outline: none;
        &:after {
          display: block;
          width: 100%;
          top: 0;
          left : 0;
          height: 100%;
          position: absolute;
          content : "";
          @include transition();
          background-color: rgba($yellow , 0);
        }
        &:hover {
          &:after {
            background-color: rgba($yellow , .3);
          }
        }
      }
   }

   img {
      width: 100%;
      // display: none;
   }

   @media (max-width : $mediaSm){
    .col1 , .col2 {
      width: 100%;
      float: none;
    }
   }
}

//Magnific
.mfp-counter {
  width: 100% !important;
  text-align: center !important;
  color: $yellow !important;
}
