.sec-features {
   background-color: $grayFor;
   
   &:after {
      @include clearfix();
   }
   .col-half {
      padding-left: 0;
      padding-right: 0;
   }
}

.feat-list {
   width: 100%;
   margin: 100px auto 0;
   padding-bottom: 50px;
   overflow: hidden;
   padding-right: 80px;
   background-color: $grayFor;

   li {
      margin-bottom: 45px;
      display:  block;
      width: 50%;  /// Just Notification
      float: left;
      padding: 0 20px;
   }

   h3 {
      color : $grayOne;
      font-weight: $bolder;
      text-transform: uppercase;
      position: relative;
      margin-left: 40px;
      margin-bottom: 25px;
      padding-left: 10px;
      &:after {
         display: block;
         content: "";
         width: 20px;
         height: 2px;
         background-color: $yellow;
         position: absolute;
         bottom: -10px;
         left: 10px;
      }
      i {
         color : $yellow;
         font-size: 40px;
         position: absolute;
         left: -40px;
         top: 0;
         font-weight: $bold;
      }
   }

   p {
      color: $grayTwo;
      @include font-size(15);
      font-family: $fontTwo;
   }

   @media (max-width : $mediaMd){
      margin: 45px auto 0;
      padding-bottom: 45px;
      padding-right: 0;
   }

   @media (max-width : $mediaSm){

      li {
         width: 100%;
         float: none;
      }
   }
}