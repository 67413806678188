.container {
   max-width: 1200px;
   padding: 0 15px;
   margin: 0 auto;
}

.row{
   margin-left: -15px;
   margin-right: -15px;
   &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
   }

   // .row {
   //    margin-left: 0;
   //    margin-right: 0;
   //    div[class^='col']{
   //       width: 100%;
   //       float: none;
   //    }
   // }
}

%col {
   float: left;
   padding-left: 15px;
   padding-right: 15px;
}

.col-two {
   @extend %col;
   width: 20%;
}

.col-third {
   @extend %col;
   width: 30%;
}

.col-tre {
   @extend %col;
   width: (100 / 3) * 1% ;
}
.col-quarter {
   @extend %col;
   width: 25%;
}

.col-for {
   @extend %col;
   width: 40%;
}
.col-half {
   @extend %col;
   width: 50%;
}

.col-sixth {
   @extend %col;
   width: 60%;
}

.col-seventh {
   @extend %col;
   width: 70%;
}

.col-eighth {
   @extend %col;
   width: 80%;
}

@media (max-width : $mediaSm){

   div[class^='col']{
      width: 100%;
      float: none;
   }
}