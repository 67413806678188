.contact-page {
   padding: 60px 15px;

   @media (max-width: $mediaSm){
      padding: 30px 15px;
   }

   .col-tre {
      text-align: center;
      margin-bottom: 40px;
      .icon-circle {
         margin: 15px auto ;
         i {
            font-size: 22px;
         }
      }
      h3 {
         text-transform: uppercase;
         font-weight: $bolder;
         @include font-size(19);
         color: $grayOne;
         margin-bottom: 15px;
      }

      p {
         color: $grayTwo;
         font-family: $fontTwo;
         @include font-size(15);
      }
   }

   .btn-blue {
      max-width: 230px;
      margin: 10px auto;
      display: block;
      padding: 15px;
   }
}

.contact-map {
   height: 470px ;
   width: 100%;
   img {
      width: 100%;
      height: 100%;
   }
   .map-img {
      background-image: url(../images/site-map.jpg);
      background-repeat: no-repeat;
      background-position: 50% 50% ;
      background-size : cover;
   }
}


// Forms

input , textarea , select {
   width: 100%;
   border : 1px solid #e3e3e3 ;
   color: $grayTwo;
   padding: 10px 20px;
   margin-bottom: 30px;
   outline: none;
   @include font-size(14);
}

textarea {
   min-height: 189px;
}